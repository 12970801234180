.modal-content {
  box-shadow: $modal-content-box-shadow-sm-up;
  border: 0;
}

.modal-content::-webkit-scrollbar {
  display: none;
}

.modal-title {
  font-size: 1.2rem;
  font-weight: normal;
}

.modal-footer {
  padding-top: 0.8rem;
}
