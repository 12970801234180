// Fields
img{
  max-width: 100%;
  max-height: 100%;
}
.form-control {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  resize: none;
  appearance: none;
  -ms-overflow-style: none;

  &:not(:disabled):not([readonly]) {
    &:focus {
      & ~ .form-group__bar {
        &:before,
        &:after {
          width: 50%;
        }
      }
    }
  }

  &:disabled,
  &[readonly]{
    opacity: 0.6;
  }

  &.is-valid,
  &.is-invalid {
    &:focus {
      box-shadow: none;
    }
  }
}

// Form Group
.form-group {
  position: relative;
}
