// Bootstrap
@import "../node_modules/bootstrap/scss/bootstrap";

// Site Styles

@import "./assets/scss/variables";
@import "./assets/scss/mixins";
@import "./assets/scss/base";
@import "./assets/scss/misc";

// Bootstrap Overrides
@import "./assets/scss/bootstrap-overrides/buttons";
@import "./assets/scss/bootstrap-overrides/dropdown";
@import "./assets/scss/bootstrap-overrides/buttons";
@import "./assets/scss/bootstrap-overrides/tables";
@import "./assets/scss/bootstrap-overrides/forms";
@import "./assets/scss/bootstrap-overrides/input-groups";
@import "./assets/scss/bootstrap-overrides/custom-forms";
@import "./assets/scss/bootstrap-overrides/close";
@import "./assets/scss/bootstrap-overrides/modal";

// fonts
@import "./fontastic-font.css";

@import "ngx-toastr/toastr";

@import "@angular/material/prebuilt-themes/indigo-pink.css";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

:root {
  --background: #f3f3f3;
  --primary: #9d1d27;
  --black: #222222;
  --white: #ffffff;
  --secblackone: #222222;
  --grey: #54565b;
  --greyborder: #dfe4ea;
  --tertiary: #9d1d27;
  --bg-gray: #f6f6f6;
  --highlight-primary: #7c1f1d;
  --text-secondary: #666;
  --light-purple: #e1e1eb;
  --main-font: "Inter", sans-serif
}

.f700 {
  font-weight: 700;
}

.bg-white {
  background-color: var(--white);
}

.color-gray {
  color: var(--grey);
}

.black {
  color: var(--black);
}

.reward-points-bg {
  color: var(--primary);
  background-color: #ffcb05;
}

.first_family {
  .reward-points-bg {
    background: #25243b;
  }
}

.classic {
  .reward-points-bg {
    background: linear-gradient(95.75deg, #0c2560 1.45%, #12306b 121.61%);
  }
}

.wealth {
  .reward-points-bg {
    background: linear-gradient(95.75deg, #353535 1.45%, #000000 121.61%);
  }
}
.select {
  .reward-points-bg {
    background: linear-gradient(95.75deg, #681610 1.45%, #8a231d 121.61%);
  }
}
.private {
  .reward-points-bg {
    background: #56070c; //Got the hex in mail from yash : REF
  }
}

.millenial {
  .reward-points-bg {
    background: linear-gradient(95.75deg, #2e64ac 1.45%, #397db4 121.61%);
  }
}

.private,
.select,
.wealth,
.classic,
.first_family,
.millenial {
  .reward-points-bg {
    *:not(.redemption-fee-note) {
      color: #ffffff;
    }
    .redemption-fee-note,
    .redemption-fee-note > * {
      color: #ffffffa8;
    }
    button.btn-outline-custom,
    .btn-outline-custom:hover {
      border: 1px solid #ffffff !important;
      background-color: transparent !important;
      color: #ffffff !important;
    }
  }
}

.text-color {
  color: var(--primary);
}

.toast-success {
  background-color: #00a67a;
  color: white !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

.toast-error {
  background-color: #9d1d27;
  color: white !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}
.toast-close-button {
  position: static;
  font-size: 25px !important;
  color: white !important;
}
.toast-container .ngx-toastr {
  width: 450px !important;
  @media (max-width: 576px) {
    width: 90vw;
  }
  width: 350px !important;
}

.toast-container .ngx-toastr.addToCardToast {
  width: fit-content !important;
  padding: 0;
  border-radius: 30px;
  margin-top: 60px;
}

.toastMessage {
  border-radius: 30px;
  width: fit-content !important;
  padding: 12px 25px;
}

.toast-top-right {
  @media (max-width: 576px) {
    top: 0px;
    right: 0px;
  }
}

.bg {
  background-color: var(--background);
}

.ngx-pagination {
  display: flex;
  align-items: center;
  font-weight: 500;

  .current {
    color: white;
    cursor: default;
    background: #9d1d27 !important;
    border-radius: 50%;
    text-align: center;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--main-font);
}

.authenticationModal {
  width: 420px !important;
  max-width: 420px !important;
  @media (max-width: 768px) {
    max-width: 90vw !important;
  }
  @media (max-width: 386px) {
    max-width: unset !important;
  }
  .mat-dialog-container {
    overflow: visible !important;
  }
}

.btn-outline-custom {
  border: 1px solid transparent !important;
  border-color: #9d1d27 !important;
  color: #9d1d27 !important;
  border-radius: 32px !important;
  background-color: transparent !important;
  text-transform: capitalize !important;
  padding: 2% 9% !important;
}

.btn-outline-custom:hover {
  color: #ffffff !important;
  background-color: #9d1d27 !important;
}

.modal-dialog:not(.not-center) {
  top: 50%;
  transform: translateY(-50%) !important;
  max-height: 100%;
  height: auto;
}

@media print {
  .hidden-print {
    display: none !important;
  }

  body * {
    visibility: hidden;
  }
  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.redemption-fee-note {
  color: var(--grey);
  .icon-info {
    margin-top: 3px;
    @media (max-width: 768px) {
      margin-top: 2px;
    }
  }
  .note-label {
    float: left;
    height: 15px;
  }
}

.vistara-modal {
  margin: 0;
  height: 100vh !important;
  background: rgba(0, 0, 0, 0.5);
  .modal-content {
    border-radius: 10px 10px 0 0;
    position: absolute;
    bottom: 0;
  }
}

.redemption-modal {
  margin: 0;
  height: 100vh !important;
  .modal-content {
    height: 100vh;
  }
}
